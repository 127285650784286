@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;700;800&display=swap');
.box{
    /* background: linear-gradient(50deg, #01015e, #190b99); */
    background-color: #f8034e;
    /* height:200px; */
    padding:45px 20px 45px 20px;
    text-align: center;
    color:white;
    font-family: 'Poppins',sans-serif;
    font-weight: 500;
}

.points{
    background:#fff;
    color:#f8034e;
    font-size: 28pt;
    width:min-content;
    padding:0px 15px 0px 15px;
    font-family: 'Poppins',sans-serif;
    font-weight: 800;
    text-align: center;
    border-radius: 9px;
    margin-top: 0px;
    min-width:100px
  
}