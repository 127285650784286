
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,900;1,700&display=swap');


body > #root > div {
  /* max-height: 100vh; */
  max-width:100%;
  font-family: 'Poppins', sans-serif !important;

}


.main{
  margin-top:55px;
  max-width:100%;
  margin-bottom: 70px;
  /* margin-bottom: 100px !important; */
}

.App-logo {
  height: 32px;
  pointer-events: none;
}


.t-l{
  text-align: left;
}

/* 

.App {
  text-align: left;
 
}
.App-logo {
  height: 32px;
  pointer-events: none;
}

.logo-container{
  padding:9px 0 9px 9px;
}

.mt_5{
  margin-top:5px;
}
.mt_40vh{
  margin-top:40vh;
}

/* Nav */
/* nav {
  display:flex;
  justify-content: space-around;
  align-items: center;
  min-height: 9vh;
  min-width:100%;
  background: linear-gradient(50deg, #01015e, #190b99) !important;
  color: #fff;
  position:fixed;
  top:0px;
  z-index: 100;
} */

/* .navHolder{
  padding-bottom: 10vh;
} */
/* .nav-links{
  list-style-type: none;
  width:45%;
  display:flex;
  justify-content: space-around;
  align-items: right;
  vertical-align: middle;
  padding:15px 0 0px 0;
} */

/* .footerNav{
  top:90vh;
  margin-top:90vh;
}  */
/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */